import { useEffect, useRef } from "react";

const VideoPlayer = () => {
  const firstRender = useRef(false);
  const VideoPlayerContainer = useRef<any>(null);

  useEffect(() => {
    if (!firstRender.current) {
      const videoPlayerScript = document.createElement("script");

      videoPlayerScript.src =
        "https://content.viralize.tv/display/?zid=AAFqTWtkmWIsIgcc";
      videoPlayerScript.type = "text/javascript";
      videoPlayerScript.setAttribute("data-wid", "auto");

      VideoPlayerContainer.current.appendChild(videoPlayerScript);

      firstRender.current = true;
    }
  }, [firstRender]);

  return <div ref={VideoPlayerContainer} />;
};

export default VideoPlayer;
